import axios from 'axios'
import { Toast } from "vant";
import 'vant/lib/toast/style';
import { getToken } from '@/utils/auth'

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000 // request timeout
})
let loading;
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json'
        config.headers['Accept'] = 'application/json,text/html, */*'
        // config.headers['X-PLATFORM'] = 'web'
        config.headers['Authorization'] = 'Bearer ' + getToken()

        config.url = config.url.replace(config.baseURL + '/', config.baseURL)
        //
        // // 自定义加载图标
        // loading = Toast.loading({
        //     message: '加载中...',
        //     forbidClick: true,
        //     loadingType: 'spinner',
        //     position: 'bottom',
        // });
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        Toast.clear();
        // eslint-disable-next-line no-prototype-builtins
        if (res.hasOwnProperty('error')) {

            if (res.error.code === 401) {
                Toast.fail({ message: '登录失败', position: 'bottom' })

            } else {
                // Toast.fail({ message: res.error.message || 'Error', position: 'bottom' })
                return Promise.reject(new Error(res.error.message || 'Error'))
            }
        } else {
            return res
        }
    },
    error => {
        // Toast.fail({ message: error.message, position: 'bottom' })
        return Promise.reject(error)
    }
)
export const BASE_URL = process.env.VUE_APP_API_URL
export default service
