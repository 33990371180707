
export function getToken() {
  // console.log(queryString('token'))
  return queryString('token')
}

export function getRoomId() {

  return queryString('room_id')
}

export function setToken(token) {

}

export function removeToken() {

}


function queryString(name) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  let regexS = "[\\?&]" + name + "=([^&#]*)";
  let regex = new RegExp(regexS);
  let results = regex.exec(window.location.search);
  if(results === null) {
    return "";
  }
  else {
    return decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}
